
import { Component, Vue } from "vue-property-decorator";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { IMerchant } from "@/typescript/interfaces/IMerchantResponse";

@Component
export default class extends Vue {
    readonly name: string = "AppView";
    navbar: boolean = false;

    get merchantData(): IMerchant {
        return this.$store.getters["GET_MERCHANT_DATA"];
    }

    created(): void {
        this.$store.dispatch("START_GPS_TRACKING");
    }

    async mounted(): Promise<void> {
        await this.$store.dispatch("LOAD_MERCHANT");
        await this.$store.dispatch("RESTORE_SESSION");

        if(isEmpty(this.$store.getters['GET_ACCESS_TOKEN'])) {
            this.$router.push('/login').catch(noop);
        }
    }

    get routeName(): string {
        return this.$route.name as string;
    }

    get username(): string {
        return this.$store.getters["GET_USERNAME"];
    }

    async logout(): Promise<void> {
        await this.$store.dispatch("LOGOUT");
        this.$router.push('/login').catch(noop);
    }
}

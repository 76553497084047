
import packageJson from "../../package.json";
import { Component, Vue } from "vue-property-decorator";
import { IMerchant } from "@/typescript/interfaces/IMerchantResponse";

@Component
export default class extends Vue {
    readonly name: string = "LoginView";

    username: string = "";
    password: string = "";
    isLoggingIn: boolean = false;

    get version(): string {
        return packageJson.version;
    }

    get merchantData(): IMerchant {
        return this.$store.getters["GET_MERCHANT_DATA"];
    }

    async login(): Promise<void> {
        try {
            if (this.isLoggingIn) return;
            this.isLoggingIn = true;
            const success = await this.$store.dispatch("LOGIN", {
                username: this.username,
                password: this.password
            });

            if (success) {
                await this.$router.push("/");
            } else {
                alert("Login failed.");
            }
        } catch (error) {
            alert("Login failed.");
        } finally {
            this.isLoggingIn = false;
        }
    }
}

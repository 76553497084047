
import IButtonData from "@/typescript/interfaces/IButtonData";
import { Component, Vue } from "vue-property-decorator";

@Component({
    props: {
        items: {
            required: true,
            type: Array as () => IButtonData[]
        }
    }
})
export default class extends Vue {
    readonly name: string = "Buttons";
}

import axios, { AxiosResponse } from "axios";
import store from "@/store";
import isNil from "lodash/isNil";
import ILoginResponse from "@/typescript/interfaces/ILoginResponse";
import ICiclosResponse from "@/typescript/interfaces/ICiclosResponse";
import ICiclosListsResponse from "@/typescript/interfaces/ICiclosListsResponse";
import ICiclosListsItemsResponse from "@/typescript/interfaces/ICiclosListsItemsResponse";
import IMerchantResponse from "@/typescript/interfaces/IMerchantResponse";
import ICiclosHistoryRequest from "@/typescript/interfaces/ICiclosHistoryRequest";

const api = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? "https://dev.webgerencial.net" : "/",
    headers: {
        "Content-Type": "application/json"
    }
});

api.interceptors.request.use((config) => {
    if (store.getters["GET_ACCESS_TOKEN"] === null) {
        return config;
    }

    if(isNil(config.headers)) {
        config.headers = {};
    }

    config.headers.Authorization = `Bearer ${store.getters["GET_ACCESS_TOKEN"]}`;

    return config;
});

export default {
    LOGIN: async (username: string, password: string): Promise<AxiosResponse<ILoginResponse>> => api.put("/api/login/ciclos_dev", {
        username,
        password,
        user_agent: navigator.userAgent
    }),
    CICLOS: async (): Promise<AxiosResponse<ICiclosResponse>> => await api.get("/api/ciclos"),
    CICLOS_LISTS: async (): Promise<AxiosResponse<ICiclosListsResponse>> => await api.get("/api/ciclos_lists"),
    CICLOS_LISTS_ITEMS: async (): Promise<AxiosResponse<ICiclosListsItemsResponse>> => await api.get("/api/ciclos_lists_items"),
    CICLOS_HISTORY: async (request: ICiclosHistoryRequest): Promise<AxiosResponse<unknown>> => await api.post("/api/ciclos_history", request),
    MERCHANT: async (id_merchant: string): Promise<AxiosResponse<IMerchantResponse>> => await api.get(`/api/merchant/${id_merchant}`),
};


import { Component, Vue, Watch } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import noop from "lodash/noop";
import Buttons from "@/components/Buttons.vue";
import IButtonData from "@/typescript/interfaces/IButtonData";
import { ICiclo } from "@/typescript/interfaces/ICiclosResponse";
import isEmpty from "lodash/isEmpty";
import mapper from "@/plugins/mapper";
import { ICicloList } from "@/typescript/interfaces/ICiclosListsResponse";
import { ICiclosListsItem } from "@/typescript/interfaces/ICiclosListsItemsResponse";
import State from "@/typescript/enums/State";
import ICicloSelection from "@/typescript/interfaces/ICicloSelection";
import ICiclosHistoryRequest, { ICicloSelectionRequest } from "@/typescript/interfaces/ICiclosHistoryRequest";
import { v4 as uuidv4 } from "uuid";
import IPosition from "@/typescript/interfaces/IPosition";
import api from "@/plugins/api";
import { convertDate } from "@/plugins/utils";

@Component({
    components: { Buttons }
})
export default class extends Vue {
    readonly name: string = "HomeView";
    currentItems: IButtonData[] = [];
    progress: IButtonData[][] = [];

    state: State = State.Ciclo;
    cicloId: string = "";
    cicloName: string = "";
    cicloListIndex: number = -1;
    cicloSelection: Map<string, ICicloSelection> = new Map();
    endStateStartTime: number = 0;
    endStateStartLocation: IPosition = { latitude: 0, longitude: 0, time: 0 };
    endTimeDisplay: string = "00:00:00";
    isRequestInProgress: boolean = false;

    get PageState(): typeof State {
        return State;
    }

    get username(): string {
        return this.$store.getters["GET_USERNAME"];
    }

    get isCiclosLoading(): boolean {
        return this.$store.getters["GET_IS_CICLOS_LOADING"];
    }

    get ciclos(): ICiclo[] {
        return this.$store.getters["GET_CICLOS"];
    }

    get ciclosLists(): ICicloList[] {
        return this.$store.getters["GET_CICLOS_LISTS"].filter((item: ICicloList) => item.ciclo_id === this.cicloId);
    }

    get ciclosListsItems(): ICiclosListsItem[] {
        return this.$store.getters["GET_CICLOS_LISTS_ITEMS"].filter((item: ICiclosListsItem) => item.ciclo_list_id === this.ciclosLists[this.cicloListIndex].ciclo_list_id);
    }

    mounted(): void {
        setInterval(() => {
            if(this.state !== State.End || this.isRequestInProgress) {
                return;
            }

            const diff = new Date().getTime() - this.endStateStartTime;
            const seconds = Math.floor((diff / 1000) % 60);
            const minutes = Math.floor((diff / 1000 / 60) % 60);
            const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);

            this.endTimeDisplay = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        }, 250);
    }

    @Watch("isCiclosLoading")
    async onChange(): Promise<void> {
        if (isEmpty(this.cicloId)) {
            this.currentItems = this.ciclos.filter((item: ICiclo) => item.active).map((item: ICiclo) => mapper.CicloToButtonData(item, () => {
                this.state = State.List;
                this.cicloId = item.ciclo_id;
                this.cicloName = item.ciclo_name;
                this.cicloListIndex = -1;
                this.currentItems = [];
                this.progress.push([mapper.CicloToButtonData(item, noop)]);
                this.nextCicleList();
            }));
        }
    }

    nextCicleList(): void {
        this.cicloListIndex++;
        this.currentItems = [];

        if(this.ciclosLists.length === 0) {
            alert(1);
            return;
        }

        if (this.cicloListIndex >= this.ciclosLists.length) {
            this.state = State.End;
            this.endStateStartTime = new Date().getTime();
            this.endStateStartLocation = cloneDeep(this.$store.getters["GET_POSITION"]);
            return;
        }

        let index = this.cicloListIndex;

        this.progress.push([mapper.CicloListToButtonData(this.ciclosLists[this.cicloListIndex], () => {
            if(this.state === State.End) {
                return;
            }

            this.cicloListIndex = index - 1;
            this.progress.splice(index + 1, this.progress.length - index + 1);
            this.nextCicleList();
        })]);

        this.loadCicloListItems();
    }

    async loadCicloListItems(): Promise<void> {
        this.currentItems = this.ciclosListsItems.map((item: ICiclosListsItem) => mapper.CicloListItemToButtonData(item, async () => {
            this.progress[this.cicloListIndex + 1].push(mapper.CicloListItemToButtonData(item, noop));

            this.cicloSelection.set(item.ciclo_list_id, {
                cicloId: this.cicloId,
                cicloListId: item.ciclo_list_id,
                cicloListItemId: item.ciclo_list_item_id,
                cicloName: this.cicloName,
                cicloListName: this.ciclosLists[this.cicloListIndex].ciclo_list_name,
                cicloListItemName: item.ciclo_item_description,
                position: cloneDeep(this.$store.getters['GET_POSITION']),
                time: new Date().getTime()
            });
            this.nextCicleList();
        }));
    }

    reset(): void {
        this.state = State.Ciclo;
        this.cicloId = "";
        this.cicloName = "";
        this.cicloListIndex = -1;
        this.cicloSelection = new Map();
        this.endStateStartTime = 0;
        this.endTimeDisplay = "00:00:00";
        this.endStateStartLocation = { latitude: 0, longitude: 0, time: 0 };
        this.progress = [];
        this.currentItems = [];

        this.onChange();
    }

    async finish(): Promise<void> {
        await this.makeRequest(true);
    }

    async cancel(): Promise<void> {
        await this.makeRequest(false);
    }

    async makeRequest(finish: boolean): Promise<void> {
        if (this.isRequestInProgress) {
            return;
        }

        try {
            this.isRequestInProgress = true;

            const request = await this.buildRequest(finish);
            console.log(finish ? "finish" : "cancel", request);

            await api.CICLOS_HISTORY(request);
        } catch (e) {
            alert("Fatal error");
            console.error(e);
        } finally {
            this.isRequestInProgress = false;
            this.reset();
        }
    }

    async buildRequest(finish: boolean): Promise<ICiclosHistoryRequest> {
        const cicloListById = (cicloListId: string): ICicloList | undefined => {
            return this.ciclosLists.find((item: ICicloList) => item.ciclo_list_id === cicloListId);
        }

        // eslint-disable-next-line no-async-promise-executor
        await new Promise<void>(async (resolve) => {
            let resolved = false;

            const resolveOnce = (): void => {
                if(resolved) {
                    return;
                }

                resolved = true;
                resolve();
            }

            setTimeout(resolveOnce, 3000);

            await this.$store.dispatch("FORCE_GPS_TRACKING");
            resolveOnce();
        });
        const position = cloneDeep(this.$store.getters["GET_POSITION"]);

        const cicloSelection = Array.from(this.cicloSelection.keys()).map(cicloListId => {
            const selectionMap = this.cicloSelection.get(cicloListId) || {} as ICicloSelection;

            const selectionRequest: ICicloSelectionRequest = {
                ciclo_list_id : cicloListId,
                ciclo_list_name :  cicloListById(cicloListId)?.ciclo_list_name ?? "",
                ciclo_list_item_id : selectionMap.cicloListItemId,
                ciclo_list_item_description : selectionMap.cicloListItemName,
                ciclo_datetime : convertDate(new Date(selectionMap.time)),
                ciclo_location : `point(${selectionMap.position.longitude},${selectionMap.position.latitude})`
            }

            return selectionRequest;
        });

        return {
            ciclos_history: [{
                ciclo_history_id: uuidv4(),
                ciclo_id: this.cicloId,
                id_merchant: this.$store.getters['GET_MERCHANT_ID'],
                id_user: this.$store.getters['GET_USER_ID'],
                ciclo_history_start_datetime: convertDate(new Date(this.endStateStartTime)),
                ciclo_history_start_location: `point(${this.endStateStartLocation.longitude},${this.endStateStartLocation.latitude})`,
                ciclo_history_end_datetime: finish ? convertDate(new Date()) : "",
                ciclo_history_end_location: finish ? `point(${position.longitude},${position.latitude})` : "",
                ciclo_history_cancel_datetime: finish ? "" : convertDate(new Date()),
                ciclo_history_cancel_location: finish ? "" : `point(${position.longitude},${position.latitude})`,
                ciclo_selection: JSON.stringify(cicloSelection),
                inserted_datetime: convertDate(new Date()),
            }]
        };
    }
}

export async function getPosition(): Promise<GeolocationPosition> {
    if (!navigator.geolocation)
        throw new Error("failed");

    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    });
}

export function getPositionContinuously(callback: (position: GeolocationPosition) => void): void {
    if (!navigator.geolocation)
        throw new Error("failed");

    navigator.geolocation.watchPosition((position) => {
        callback(position);
    });
}


import Vue from "vue";
import Vuex from "vuex";
import noop from "lodash/noop";
import api from "@/plugins/api";
import { ICiclo } from "@/typescript/interfaces/ICiclosResponse";
import { ICicloList } from "@/typescript/interfaces/ICiclosListsResponse";
import { ICiclosListsItem } from "@/typescript/interfaces/ICiclosListsItemsResponse";
import { IMerchant } from "@/typescript/interfaces/IMerchantResponse";
import IPosition from "@/typescript/interfaces/IPosition";
import { getPosition, getPositionContinuously } from "@/plugins/geolocation";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        merchantId: "ciclos_dev",
        username: "",
        userId: "",
        accessToken: "",
        isCiclosLoading: false,
        ciclos: [],
        ciclosLists: [],
        ciclosListsItems: [],
        merchantData: {},
        position: {},
        gpsInterval: null,
    },
    getters: {
        GET_ACCESS_TOKEN(state): string {
            return state.accessToken;
        },
        GET_CICLOS(state): ICiclo[] {
            return state.ciclos;
        },
        GET_CICLOS_LISTS(state): ICicloList[] {
            return state.ciclosLists;
        },
        GET_CICLOS_LISTS_ITEMS(state): ICiclosListsItem[] {
            return state.ciclosListsItems;
        },
        GET_USERNAME(state): string {
            return state.username;
        },
        GET_MERCHANT_ID(state): string {
            return state.merchantId;
        },
        GET_MERCHANT_DATA(state): IMerchant {
            return state.merchantData as unknown as IMerchant;
        },
        GET_IS_CICLOS_LOADING(state): boolean {
            return state.isCiclosLoading;
        },
        GET_POSITION(state): IPosition {
            return state.position as unknown as IPosition;
        },
        GET_USER_ID(state): string {
            return state.userId;
        },
        GET_GPS_INTERVAL(state): number | null {
            return state.gpsInterval;
        }
    },
    mutations: {
        SET_ACCESS_TOKEN(state, accessToken): void {
            state.accessToken = accessToken;
            localStorage.setItem("accessToken", accessToken);
        },
        SET_CICLOS(state, ciclos): void {
            state.ciclos = ciclos;
        },
        SET_CICLOS_LISTS(state, ciclosLists): void {
            state.ciclosLists = ciclosLists;
        },
        SET_CICLOS_LISTS_ITEMS(state, ciclosListsItems): void {
            state.ciclosListsItems = ciclosListsItems;
        },
        SET_CICLOS_LOADING(state, isCiclosLoading): void {
            state.isCiclosLoading = isCiclosLoading;
        },
        SET_USERNAME(state, username): void {
            state.username = username;
            localStorage.setItem("username", username);
        },
        SET_MERCHANT_DATA(state, merchantData): void {
            state.merchantData = merchantData;
        },
        SET_POSITION(state, position): void {
            state.position = position;
        },
        SET_USER_ID(state, userId): void {
            state.userId = userId;
            localStorage.setItem("userId", userId);
        },
        SET_GPS_INTERVAL(state, gpsInterval): void {
            state.gpsInterval = gpsInterval;
        }
    },
    actions: {
        async RESTORE_SESSION({ commit, dispatch }): Promise<void> {
            const accessToken = localStorage.getItem("accessToken");
            const username = localStorage.getItem("username");
            const userId = localStorage.getItem("userId");

            if (isNil(accessToken) || isEmpty(accessToken)) {
                return;
            }

            commit("SET_ACCESS_TOKEN", accessToken);
            commit("SET_USERNAME", username);
            commit("SET_USER_ID", userId);

            try {
                await api.CICLOS();
                dispatch("LOAD_CICLOS_DATA").then(noop).catch(() => alert("Fatal error while loading data."));
            } catch (e) {
                console.log("Failed to restore session");
                commit("SET_ACCESS_TOKEN", "");
                localStorage.removeItem("accessToken");
            }
        },
        async LOGIN({ commit, dispatch }, { username, password }): Promise<boolean> {
            try {
                const { data: { access_token, variables } } = await api.LOGIN(username, password);
                commit("SET_ACCESS_TOKEN", access_token);
                commit("SET_USERNAME", username);
                commit("SET_USER_ID", JSON.parse(variables)["id_user"]);
            } catch (error) {
                console.error(error);
                return false;
            }

            dispatch("LOAD_CICLOS_DATA").then(noop).catch(() => alert("Fatal error while loading data."));
            return true;
        },
        async LOAD_CICLOS_DATA({ commit }): Promise<void> {
            commit("SET_CICLOS_LOADING", true);

            const { data: { ciclos } } = await api.CICLOS();
            commit("SET_CICLOS", ciclos);

            const { data: { ciclos_lists } } = await api.CICLOS_LISTS();
            commit("SET_CICLOS_LISTS", ciclos_lists);

            const { data: { ciclos_lists_items } } = await api.CICLOS_LISTS_ITEMS();
            commit("SET_CICLOS_LISTS_ITEMS", ciclos_lists_items);

            commit("SET_CICLOS_LOADING", false);
        },
        async LOAD_MERCHANT({ commit }): Promise<void> {
            const { data: { merchants } } = await api.MERCHANT(this.getters["GET_MERCHANT_ID"]);
            commit("SET_MERCHANT_DATA", merchants[0]);
        },
        LOGOUT({ commit }): void {
            commit("SET_ACCESS_TOKEN", "");
            commit("SET_USERNAME", "");
            commit("SET_CICLOS", []);
            commit("SET_CICLOS_LISTS", []);
            commit("SET_CICLOS_LISTS_ITEMS", []);
        },
        async START_GPS_TRACKING({ commit, getters }): Promise<void> {
            const manuallyUpdatePosition = async (): Promise<void> => {
                const position = await getPosition();

                commit("SET_POSITION", {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    time: position.timestamp
                });

                console.log("Position updated manually", position);
            }

            if(isNil(getters["GET_GPS_INTERVAL"])) {
                getPositionContinuously((position) => {
                    commit("SET_POSITION", {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        time: position.timestamp
                    });

                    console.log("Position updated", position);
                });
            } else {
                clearInterval(getters["GET_GPS_INTERVAL"]);
            }

            commit("SET_GPS_INTERVAL", setInterval(manuallyUpdatePosition, 10000));
            await manuallyUpdatePosition();
        },
        async FORCE_GPS_TRACKING({ commit }): Promise<void> {
            const position = await getPosition();

            commit("SET_POSITION", {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                time: position.timestamp
            });
        }
    },
    modules: {}
});

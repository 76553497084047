import { ICiclo } from "@/typescript/interfaces/ICiclosResponse";
import IButtonData from "@/typescript/interfaces/IButtonData";
import { ICicloList } from "@/typescript/interfaces/ICiclosListsResponse";
import { ICiclosListsItem } from "@/typescript/interfaces/ICiclosListsItemsResponse";

const mapper = Object.freeze({
    CicloToButtonData(ciclo: ICiclo, action: () => void): IButtonData {
        return {
            text: ciclo.ciclo_name,
            textColor: ciclo.ciclo_text_color,
            backgroundColor: ciclo.ciclo_background_color,
            icon: ciclo.ciclo_image,
            action
        }
    },
    CicloListToButtonData(cicloList: ICicloList, action: () => void): IButtonData {
        return {
            text: cicloList.ciclo_list_name,
            textColor: cicloList.ciclo_list_text_color,
            backgroundColor: cicloList.ciclo_list_background_color,
            icon: cicloList.ciclo_list_image,
            action
        }
    },
    CicloListItemToButtonData(cicloListItem: ICiclosListsItem, action: () => void): IButtonData {
        return {
            text: cicloListItem.ciclo_item_description,
            textColor: cicloListItem.ciclo_item_text_color,
            backgroundColor: cicloListItem.ciclo_item_background_color,
            icon: cicloListItem.ciclo_item_image,
            action
        }
    }
});

export default mapper;

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isRequestInProgress)?_c(VOverlay,[_c(VProgressCircular,{attrs:{"indeterminate":""}})],1):_vm._e(),(_vm.state === _vm.PageState.Ciclo)?_c('div',{staticClass:"text-h5 text-center mt-8"},[_vm._v(" Bem vindo "+_vm._s(_vm.username)+"! ")]):_vm._e(),(_vm.state !== _vm.PageState.Ciclo)?_c(VCardActions,{staticClass:"progress-container mt-1"},[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.state === _vm.PageState.End,"color":"primary"},on:{"click":_vm.reset}},[_c(VIcon,[_vm._v(" mdi-home ")]),_vm._v(" Início ")],1),_c(VSpacer)],1):_vm._e(),_vm._l((_vm.progress),function(button,index){return _c('buttons',{key:index,staticClass:"mt-1 col-12",attrs:{"items":button}})}),(_vm.state !== _vm.PageState.End)?_c('buttons',{staticClass:"mt-5",attrs:{"items":_vm.currentItems}}):_vm._e(),(_vm.state === _vm.PageState.End)?_c('div',{staticClass:"mt-3 text-center text-h3"},[_vm._v(" "+_vm._s(_vm.endTimeDisplay)+" ")]):_vm._e(),(_vm.state === _vm.PageState.End)?_c('div',{staticClass:"mt-4 row"},[_c('buttons',{staticClass:"mt-4 col-6",attrs:{"items":[{
                action: _vm.cancel,
                icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Deletion_icon.svg/1024px-Deletion_icon.svg.png',
                text: 'Cancelar',
                textColor: 'white',
                backgroundColor: 'red'
            }]}}),_c('buttons',{staticClass:"mt-4 col-6",attrs:{"items":[{
                action: _vm.finish,
                icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB-12Sa6i8Ahb9PJi3SDciOHofNpExKLkk4LznZFCopPdvgKyPULB1DAxxrKJ_pRGOj6Q&usqp=CAU',
                text: 'Concluir',
                textColor: 'white',
                backgroundColor: 'green'
            }]}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
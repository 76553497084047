import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[(_vm.routeName !== 'login')?_c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","dense":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.navbar = true}}}),_c(VImg,{staticClass:"mx-2",attrs:{"src":require('@/assets/logo.png'),"max-height":"40","max-width":"40","contain":""}}),_c(VAppBarTitle,[_vm._v(" "+_vm._s(_vm.merchantData.name)+" ")])],1):_vm._e(),_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.navbar),callback:function ($$v) {_vm.navbar=$$v},expression:"navbar"}},[_c(VCard,{staticClass:"rounded-0",attrs:{"color":"primary","dark":""}},[_c(VListItem,[_c(VImg,{staticClass:"mx-2",attrs:{"src":require('@/assets/logofull.png'),"contain":""}})],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Olá, "+_vm._s(_vm.username)+" ")])],1)],1)],1),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" Sair ")])],1),_c(VListItemAvatar,[_c(VAvatar,[_c(VIcon,[_vm._v(" mdi-logout ")])],1)],1)],1)],1),_c('router-view',{staticClass:"mt-12"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
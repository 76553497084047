import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"pa-0 ma-0",attrs:{"justify":"center"}},[_c(VCol,{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"buttons-container"},_vm._l((_vm.items),function(item,index){return _c(VCard,{key:index,staticClass:"flex-grow-1",attrs:{"width":"200","min-height":"60","elevation":"5","color":item.backgroundColor},on:{"click":item.action}},[_c(VCardActions,{staticClass:"text-button px-2 py-3 text-center",style:({ color: item.textColor })},[(item.icon)?_c(VAvatar,{attrs:{"size":"64"}},[_c(VImg,{attrs:{"src":item.icon}})],1):_vm._e(),_c(VSpacer),_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s(item.text)+" ")])],1),_c(VSpacer)],1)],1)}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }